import React from "react";
import { useParams } from "react-router-dom";
import { Template } from "./Template";
import { Voxel } from "./Voxel";
import { NotFound } from "./NotFound";
import voxelsData from "./voxels.json";
import { Helmet } from "react-helmet";

export const VoxelDetail: React.FC<{}> = ({}) => {
  const { id } = useParams<{ id: string }>();
  const voxel = voxelsData.voxels.find((voxel) => {
    return voxel.id === Number(id);
  });
  if (voxel === undefined) {
    return <NotFound />;
  }

  const voxelComponent = (
    <Voxel
      key={voxel.id}
      id={voxel.id}
      images={voxel.images}
      date={voxel.date}
      twitter={voxel.twitter_url}
      instagram={voxel.instagram_url}
      youtube={voxel.youtube_url}
    />
  );

  return (
    <>
      <Helmet>
        <title>Voxel - {id}</title>
        <meta property="og:url" content={"https://voxel.henteko07.com/" + id} />
        <meta
          property="og:image"
          content={"https://voxel.henteko07.com" + voxel.images[0]}
        />
        <meta property="og:title" content={"Voxel - " + id} />
      </Helmet>
      <Template>
        <div className="VoxelDetail">{voxelComponent}</div>
      </Template>
    </>
  );
};
