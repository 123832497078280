import React, { Children } from "react";
import "./App.css";
import { Link } from "react-router-dom";

export const Template: React.FC<{}> = ({ children }) => {
  return (
    <div className="App">
      <div className="Header">
        <div className="Logo">
          <Link to="/">
            <p>Voxel</p>
          </Link>
        </div>
        <div className="HeaderLinks">
          <a
            href="https://www.amazon.co.jp/exec/obidos/ASIN/4774198153/henteko00-22/"
            target="_blank"
            style={{ color: "#000", marginRight: "5px" }}
          >
            Books
          </a>
          <a
            href="https://twitter.com/henteko07"
            target="_blank"
            style={{ color: "#000", marginRight: "5px" }}
          >
            Twitter
          </a>
          <a
            href="https://www.instagram.com/henteko07/"
            target="_blank"
            style={{ color: "#000" }}
          >
            Instagram
          </a>
        </div>
      </div>
      {children}
      <div className="Footer">
        Created by{" "}
        <a href="https://henteko07.com" target="_blank">
          henteko
        </a>
      </div>
    </div>
  );
};
