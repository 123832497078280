import React from "react";
import "./NotFound.css";
import { Helmet } from "react-helmet";

export const NotFound: React.FC<{}> = ({}) => {
  return (
    <>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <div id="message">
        <h2>404</h2>
        <h1>Page Not Found</h1>
        <p>
          The specified file was not found on this website. Please check the URL
          for mistakes and try again.
        </p>
      </div>
    </>
  );
};
