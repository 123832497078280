import React from "react";
import { Voxel } from "./Voxel";
import voxelsData from "./voxels.json";
import { Template } from "./Template";
import { Helmet } from "react-helmet";

export const App: React.FC<{}> = ({}) => {
  const reversedVoxels = [...voxelsData.voxels].reverse();
  const voxels = reversedVoxels.map((voxel, index) => {
    return (
      <Voxel
        key={voxel.id}
        id={voxel.id}
        images={voxel.images}
        date={voxel.date}
        twitter={voxel.twitter_url}
        instagram={voxel.instagram_url}
        youtube={voxel.youtube_url}
      />
    );
  });

  return (
    <>
      <Helmet>
        <title>Voxel</title>
        <meta property="og:url" content="https://voxel.henteko07.com" />
        <meta
          property="og:image"
          content="https://voxel.henteko07.com/images/100-1.jpg"
        />
        <meta property="og:title" content="Voxel" />
      </Helmet>
      <Template>
        <div className="VoxelContents">{voxels}</div>
      </Template>
    </>
  );
};
