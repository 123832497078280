import React from "react";
import Media from "react-media";
import { Link } from "react-router-dom";

interface VoxelProps {
  id: number;
  date: string;
  images: string[];
  twitter: string;
  instagram: string;
  youtube?: string;
}

export const Voxel: React.FC<VoxelProps> = ({
  id,
  date,
  images,
  twitter,
  instagram,
  youtube,
}) => {
  const link = "/voxels/" + id;
  return (
    <Media query="(max-width: 500px)">
      {(matches) =>
        matches ? (
          <div className="Voxel">
            <Link to={link}>
              <img
                src={images[0]}
                ref="img"
                style={{ width: "100%" }}
                alt={date}
              />
            </Link>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: "-5px",
              }}
            >
              <Link
                to={link}
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "28px",
                }}
              >
                {id}
              </Link>
              <div style={{ fontSize: "14px" }}>{date}</div>
              <div>
                {youtube !== undefined ? (
                  <a href={youtube} target="_blank" style={{ color: "#000" }}>
                    <i
                      className="fa fa-youtube-play"
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    ></i>
                  </a>
                ) : null}
                <a href={twitter} target="_blank" style={{ color: "#000" }}>
                  <i
                    className="fa fa-twitter-square"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  ></i>
                </a>
                <a href={instagram} target="_blank" style={{ color: "#000" }}>
                  <i
                    className="fa fa-instagram"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        ) : (
          <div className="Voxel">
            <Link to={link}>
              <img
                src={images[0]}
                ref="img"
                style={{ width: "100%" }}
                alt={date}
              />
            </Link>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: "-5px",
              }}
            >
              <Link
                to={link}
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "28px",
                  marginRight: "5%",
                }}
              >
                {id}
              </Link>
              <div style={{ fontSize: "14px", marginRight: "5px" }}>{date}</div>
              <div style={{}}>
                {youtube !== undefined ? (
                  <a href={youtube} target="_blank" style={{ color: "#000" }}>
                    <i
                      className="fa fa-youtube-play"
                      style={{ fontSize: "20px", marginRight: "5px" }}
                    ></i>
                  </a>
                ) : (
                  <div></div>
                )}
                <a href={twitter} target="_blank" style={{ color: "#000" }}>
                  <i
                    className="fa fa-twitter-square"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  ></i>
                </a>
                <a href={instagram} target="_blank" style={{ color: "#000" }}>
                  <i
                    className="fa fa-instagram"
                    style={{ fontSize: "20px", marginRight: "5px" }}
                  ></i>
                </a>
              </div>
            </div>
          </div>
        )
      }
    </Media>
  );
};
